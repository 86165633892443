import React from 'react';
import RoldxEmailForm from './RoldxForm';
import { Container, Grid, Typography, Box } from '@material-ui/core';
import { Link } from 'gatsby';
import styles from './RoldxHome.styles';
import { backgroundDotStyles } from '../../components/hero/heroSection.styles';

const RoldxHome = ({ roldxData, footerLogo }) => {
  const { title, description, roldxVideo, belowImageText } = roldxData;
  return (
    <Container maxWidth={false} css={styles} className="section-padding-bottom">
      <Container maxWidth="lg" className="side-padding">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="top-container"
        >
          <div css={backgroundDotStyles} className="bg-dots"></div>
          <Grid item xs={12} md={9}>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="body1" className="desc">
              {description.description}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} className="green-bg">
            <video loop autoPlay={true} muted width="100%">
              <source src={roldxVideo.file.url} type="video/mp4" />
            </video>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className="below-img-text">
              {belowImageText.belowImageText}
            </Typography>
            <Box mt={3} mb={3}>
              <RoldxEmailForm />
            </Box>
            <Box mt={3} className="footer-logo">
              <Link to="/">
                <img src={footerLogo.file.url} />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default RoldxHome;
